html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {border:0;outline:0;vertical-align:baseline;margin:0;padding:0;font:62.5%;}

img{vertical-align:bottom;width:100%}
html {font-size: 62.5%;line-height: 1.8;}

body{
*font-size:small;
*font:x-small;
-webkit-text-size-adjust: none;
}

body, textarea { @extend %yu-go; }
input, select { font-family:inherit; font-size:100%; }
select,input,button,textarea {font:99% sans-serif}

pre,code,kbd,samp,tt {
*font-size:108%;
line-height:100%;
}

table {
border-collapse:collapse;
border-spacing:0;
font-size:inherit;
font:100%;
}

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {display: block}

ul {list-style:none}

blockquote,q {quotes:none}

:focus {outline:0}

ins {text-decoration:none}

del {text-decoration:line-through}

em {font-style:normal}

hr {display:block;height:1px;border:0;border-top:1px solid #cccccc;margin:1em 0;padding:0;}

figure {display:block;margin:0;padding:0;}
svg {line-height:0;}
#defs {display:none;}