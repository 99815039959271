.sns {
  display: table;
  margin: 0 auto 5px;
  @media all and (min-width:800px) {
    display: none;
  }
  &__icon {
    display: table-cell;
    padding: 0 5px 10px 5px;
    & a {
      display: block;
      line-height: 0;
    }
    & svg {
      width: 30px;
      height: 30px;
      fill: bk(1);
    }
  }
}

.sns_fix {
  height: 75px;
  display: none;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 500;
  @media all and (min-width:800px) {
    display: block;
  }
  & .sns__icon {
    display: block;
    padding: 0 15px 15px 0;
    &:hover, &:focus {
      @extend .hover;
    }
  }
}


.key {
  position: relative;
  z-index: 1;
  padding: 0;
  &--index {
    width: 100%;
    overflow: hidden;
    text-align: center;
    &__grlogo {
      position: absolute;
      left: 5%;
      bottom: 10px;
      @media all and (min-width:800px) {
        bottom: 30px;
      }
      & svg {
        width: 250px;
        height: 73px;
        @media all and (min-width:800px) {
          width: 554px;
          height: 161px;
        }
      }
    }
    &__logo {
      text-align: center;
      margin-top: 40px;
      @media all and (min-width:560px) {
        margin-top: 100px;
        margin-bottom: 100px;
      }
      & svg {
        width: 100%;
        height: 161px;
        @media all and (min-width:560px) {
          width: 554px;
          height: 161px;
        }
      }
    }
  }
}
.video {
  
}

.key_salons {
  width: 240px;
  margin: 0 auto;
  overflow: hidden;
  @media all and (min-width:560px) {
    width: 490px;
  }
  &__salon a {
    width: 240px;
    height: 100px;
    display: block;
    background: bk(0.8);
    margin-bottom: 10px;
    @media all and (min-width:560px) {
      margin-bottom: 0;
    }
    & svg {
      display: block;
      margin: 0 auto;
    }
    &:hover, &:focus {
      background: bk(1);
      @extend %transition-all-linear;
    }
  }
  &__salon01 {
    @media all and (min-width:560px) {
      float: left;
    }
    & svg {
      width: 182px;
      height: 48px;
      padding-top: 26px !important;
    }
  }
  &__salon02 {
    @media all and (min-width:560px) {
      float: right;
    }
    & svg {
      width: 182px;
      height: 35px;
      padding-top: 33px !important;
    }
  }
}

.txt {
  &--lead {
    text-align: center;
    font-size: 2.2rem;
    line-height: 1.5;
    @media all and (min-width:650px) {
      font-size: 3rem;
    }
    &_02 {
      font-size: 2rem;
      text-align: center;
    }
  }
  &--icon {
    text-indent: -40px;
    padding-left: 40px;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--narrow {
    width: 100%;
    margin: 0 auto;
    @media all and (min-width:800px) {
      width: 700px;
    }
  }
  &--ttl {
    border-bottom: 1px dashed bk(0.5);
  }
  &--small {
    font-size: 1rem;
    line-height: 1.5;
  }
  &__gr {
    &_01 {
      width: 100%;
      @media all and (min-width:650px) {
        width: 55.5%;
        float: right;
      }
    }
  }
}

.fig {
  &--gr01 {
    padding-bottom: 30px;
    @media all and (min-width:650px) {
      width: 40%;
      padding: 0 4.5% 60px 0;
      float: left;
    }
    &_right {
      padding-bottom: 30px;
      @media all and (min-width:650px) {
        width: 40%;
        padding: 0 0 60px 4.5%;
        float: right;
      }
    }
  }
  &--gr02 {
    padding-bottom: 30px;
    @media all and (min-width:650px) {
      width: 40%;
      padding: 0 4.5% 60px 0;
      float: left;
      overflow: hidden;
    }
    & img:first-child {
      float: left;
      margin-bottom: 30px;
    }
  }
}

.hr {
  border-bottom: 1px solid bk(1);
}

.kome {
  text-indent: -1rem;
  padding-left: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
}

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block;
  @media (min-width: 800px) {
    top: -90px;
  }
}

.tbl {
  &_01 {
    width: 100%;
    margin: 0 auto;
    @media all and (min-width:800px) {
      width: 700px;
    }
    & th {
      text-align: left;
      padding: 10px;
      line-height: 1.3;
      letter-spacing: -0.1rem;
      vertical-align: middle;
      & small {
        font-size: 1rem;
        font-weight: normal;
      }
    }
    & td {
      width: 8rem;
      text-align: right;
      padding: 10px;
      font-weight: bold;
      vertical-align: middle;
    }
    & tr {
      border-bottom: 1px solid bk(0.1);
    }
  }

  &_02 {
    width: 100%;
    margin: 0 auto;
    @media all and (min-width:800px) {
      width: 700px;
    }
    & th, & td {
      line-height: 1.4;
      & small {
        font-size: 1rem;
        font-weight: normal;
      }
    }
    & th {
      width: 6rem;
      text-align: left;
      padding: 10px 10px;
      letter-spacing: -0.1rem;
      font-weight: normal;
      vertical-align: top;
      @media all and (min-width:800px) {
        width: 15rem;
      }
      & small {
        font-size: 1rem;
        font-weight: normal;
      }
    }
    & td {
      text-align: left;
      padding: 10px 10px;
      font-weight: normal;
      vertical-align: middle;
      & ul {
        @extend %list-indent02;
      }
    }
    & tr {
      border-bottom: 1px solid bk(0.1);
    }
  }
}

.brand {
  width: 100%;
  display: block;
  background-color: transparent;
  overflow: hidden;
  @media all and (min-width:650px) {
    background-color: effect01(1);
  }
  &__item {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    @media all and (min-width:650px) {
      width: 50%;
      float: left;
      margin-bottom: 0;
    }
    &__a {
      width: 75%;
      height: auto;
      display: block;
      padding: 50px 12.5%;
      background-color: wh(1);
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
      @extend %hover;
      @media all and (min-width:650px) {
        height: 290px;
      }
    }
    &--jd {
      @media all and (min-width:650px) {
        background-image: url(../../assets/images/index/bg_jd.jpg);
      }
      &__logo__svg {
        width: 243px;
        height: 65px;
        @media all and (max-width:440px) {
          width: 90%;
        }
        @media all and (min-width:650px) and (max-width:750px) {
          width: 90%;
        }
      }
    }
    &--cl {
      @media all and (min-width:650px) {
        background-image: url(../../assets/images/index/bg_clair.jpg);
      }
      &__logo__svg {
        width: 292px;
        height: 56px;
        padding: 4px 0 5px;
        @media all and (max-width:440px) {
          width: 90%;
        }
        @media all and (min-width:650px) and (max-width:890px) {
          width: 90%;
        }
      }
    }
    &__logo {
      width: 100%;
      height: auto;
      background: wh(0.85);
      position: relative;
      @media all and (min-width:650px) {
        height: 100%;
      }
      &__svg {
        display: block;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.media {
  &__tile {
    width: 100%;
    margin-bottom: 30px;
    @media all and (min-width:800px) {
      width: 30.3%;
      padding: 1.5%;
//      margin-bottom: 45px;
      float: left;
    }
    &_50 {
      width: 47%;
      padding: 1.5%;
      float: left;
      margin-bottom: 15px;
      @media all and (min-width:800px) {
        width: 30.3%;
        padding: 1.5%;
//        margin-bottom: 45px;
        float: left;
      }
      
      &:last-of-type {
        margin-bottom: 45px;
      }
    }
    & a {
      display: block;
    }
    &:hover, &:focus {
      background: wh(0.3);
      @extend %transition-all-linear;
    }
  }
  &__eyeImg {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../../assets/images/common/thum.jpg);
    &_50 {
      @extend .media__eyeImg;
      height: 120px;
    }
    @media all and (min-width:650px) {
      height: 300px;
    }
    @media all and (min-width:800px) {
      height: 200px;
    }
    &_yoshihiro {
      background-image: url(../../assets/images/salon/salon_yoshihiro_thum.jpg);
    }
    &_omori {
      background-image: url(../../assets/images/salon/salon_omori_thum.jpg);
    }
    &_atreomori {
      background-image: url(../../assets/images/salon/salon_atreomori_thum.jpg);
    }
    &_kugahara {
      background-image: url(../../assets/images/salon/salon_kugahara_thum.jpg);
    }
    &_kakinokizaka {
      background-image: url(../../assets/images/salon/salon_kakinokizaka_thum.jpg);
    }
    &_atrekawasaki {
      background-image: url(../../assets/images/salon/salon_atrekawasaki_thum.jpg);
    }
    &_musashishinjo {
      background-image: url(../../assets/images/salon/salon_musashishinjo_thum.jpg);
    }
    &_tomioka {
      background-image: url(../../assets/images/salon/salon_tomioka_thum.jpg);
    }
    &_hakuraku {
      background-image: url(../../assets/images/salon/salon_hakuraku_thum.jpg);
    }
    &_umeyashiki {
      background-image: url(../../assets/images/salon/salon_umeyashiki_thum.jpg);
    }
    &_omorimachi {
      background-image: url(../../assets/images/salon/salon_omorimachi_thum.jpg);
    }
    &_nakanobe {
      background-image: url(../../assets/images/salon/salon_nakanobe_thum.jpg);
    }
  }
  &__txt {
    margin-top: 20px;
    &--date {
      @extend %josef;
      color: effect02(1);
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1;
    }
    &--ttl {
      height: 4rem;
      font-size: 1.7rem;
      font-weight: bold;
      overflow: hidden;
    }
    &--excerpt {
      font-size: 1.3rem;
      & span {
        @extend %josef;
        color: effect02(1);
        font-weight: 400;
      }
    }
    &--nm {
      &_en {
        @extend %josef;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        @media all and (min-width:650px) {
          font-size: 1.85rem;
        }
      }
      &_ja {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
    &_short {
      display: block;
      margin-top: 0;
      text-align: center;
      @media all and (min-width:650px) {
        padding-top: 5px;
        display: table-cell;
        text-align: left;
      }
    }
  }
  &__info {
    display: table;
    width: 90%;
    padding: 15px 5% 0;
  }
  &__btn {
    width: 114px;
    display: block;
    vertical-align: top;
    overflow: hidden;
    margin: 5px auto 0;
    @media all and (min-width:650px) {
      display: table-cell;
      margin: inherit;
      margin: 0 auto;
    }
    &__item {
      float: left;
      margin-left: 7px;
      & a {
        width: 50px;
        height: 50px;
        display: block;
        background: sub01(1);
        border-radius: 100px;
        text-align: center;
        &:hover, &:focus {
          @extend %hover;
        }
      }
      &--txt {
        color: effect01(1);
        @extend %josef;
        line-height: 1;
        font-size: 1.1rem;
        padding-top: 14px;
      }
      &--icon {
        fill: sub02(1);
        display: block;
        margin: 0 auto;
        &_list {
          width: 17px;
          height: 11px;
          margin-top: 4px;
        }
        &_spot {
          width: 12px;
          height: 17px;
          margin-top: 1px;
        }
      }
    }
  }
  &__reservation {
    width: 100%;
    
  }
}

.split {
  color: transparent;
  letter-spacing: -0.5rem;
}

.index {
  &__recruit {
    background: url(../../assets/images/index/bg_recruit.jpg) right top no-repeat;
    background-size: cover;
    & .container__body {
      width: 80%;
      background: bk(0.45);
      padding: 5%;
      color: wh(1);
    }
    & .ttl__h2 {
      color: effect01(1);
      &__svg {
        fill: effect01(1);
      }
      &:after {
        display: none;
      }
    }
  }
}

#ig {
  & .ttl__h2 {
    margin-bottom: 15px;
  }
}

#sbi_images {
  width: 95%;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  @media all and (min-width:930px) {
    width: 830px;
  }
}

.sbi_item {
  width: 32%;
  height: 32%;
  margin-bottom: 10px;
  overflow: hidden;
  @media all and (min-width:520px) {
    width: 24%;
    height: 24%;
  }
}

.sbi_photo {
  height: 200px;
  display: flex;
  & > img {
    display: block;
  } 
}


.news {
  &__h3 {
    &--date {
      @extend .media__txt--date;
      font-size: 1.8rem;
      border-top: 1px dashed sub02(1);
      padding-top: 20px;
      margin-bottom: 5px;
    }
    &--ttl {
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 1.6;
      font-weight: normal;
      @extend .spacer_30;
      border-bottom: 1px dashed sub02(1);
      padding-bottom: 15px;
      @media all and (min-width:800px) {
        font-size: 2.8rem;
        line-height: inherit;
      }
    }
  }
}

.tl {
  overflow: hidden;
  background-image: url(../../assets/images/recruit/dot_v.png), url(../../assets/images/recruit/dot_h.png) ;
  background-repeat: repeat-y, repeat-x;
  background-position: center top, center 40px;
  @media all and (min-width:800px) {
    background-position: center top, center 67px;
  }
  &__r {
    & dt {
      float: right;
    }
    & dd {
      float: left;
    }
  }
  &__l {
    & dt {
      float: left;
      & .tl__dt--time {
        text-align: right;
      }
      & .tl__dt--step {
        display: inline-block;
      }
    }
    & dd {
      float: right;
    }
  }
  &__dt {
    width: 42.55%;
    padding: 5% 0 5% 0;
    line-height: 1.7;
    &--time {
      @extend %josef;
      color: sub03(1);
      font-size: 4rem;
      margin-bottom: 15px;
      display: block;
      line-height: 1;
      background: base01(1);
      @media all and (min-width:800px) {
        font-size: 5rem;
      }
    }
    &--step {
      color: sub03(1);
      font-size: 2.2rem;
      padding-bottom: 5px;
      display: block;
      line-height: 1;
      letter-spacing: -0.1rem;
      background: base01(1);
      @media all and (min-width:800px) {
        font-size: 3.2rem;
      }
      & svg {
        width: 22px;
        height: 19px;
        fill: bk(0.25);
        padding: 0 5px;
        @media all and (min-width:800px) {
          width: 28px;
          height: 26px;
          padding-left: 15px;
          padding: 0 10px;
        }
      }
    }
    &--ttl {
      font-size: 2.4rem;
      display: block;
      font-weight: bold;
      padding-bottom: 15px;
      line-height: 1.2;
      background: base01(1);
      @media all and (min-width:800px) {
        font-size: 3.6rem;
      }
      & b {
        letter-spacing: -0.8rem;
      }
    }
    &--txt {
      display: block;
      margin-bottom: 15px;
    }
  }
  &__dd {
    width: 42.55%;
    & figure {
      width: 100%;
    }
  }
  &__area {
    width: 100%;
    overflow: hidden;
    @media all and (min-width:1000px) {
      margin: 0 auto;
      width: 940px;
    }
  }
}

.recruit {
  &_01 {
    padding: 30px 0;
    background: url(../../assets/images/recruit/bg_eiffel.jpg) no-repeat;
    background-position: 60% bottom;
    background-size: cover;
    @media all and (min-width:560px) {
      background-position: left bottom;
      background-size: 120%;
    }
    @media all and (min-width:650px) {
      padding: 90px 0;
    }
    @media all and (min-width:800px) {
      background-position: left bottom;
    }
    @media all and (min-width:1000px) {
      background-position: left bottom;
      background-size: 100%;
    }
    @media all and (min-width:1100px) {
      background-position: center bottom;
      background-size: cover;
    }
    &__txt {
      width: 100%;
      color: wh(1);
      text-shadow: 1px 1px 1px bk(1);
      @media all and (min-width:560px) {
        width: 500px;
      }
      &_01 {
        font-size: 1.4rem;
        @media all and (min-width:560px) {
          text-align: center;
        }
      }
    }
  }

  &_02 {
    background: url(../../assets/images/recruit/bg_salon.jpg) center top no-repeat;
    background-size: cover;
    &__body {
      padding: 5%;
      @media all and (min-width:780px) {
        padding: 2%;
      }
      &:first-child {
        border-bottom: 15px solid base01(1);
      }
    }
    &__dl {
      background: wh(0.7);
      padding: 5%;
      overflow: hidden;
      @media all and (min-width:780px) {
        padding: 0;
      }
      &_gr01 {
        & dt {
          @media all and (min-width:780px) {
            float: right;
          }
        }
        & dd {
          @media all and (min-width:780px) {
            float: left;
          }
        }
      }
      &_gr02 {
        & dt {
          @media all and (min-width:780px) {
            float: left;
          }
        }
        & dd {
          @media all and (min-width:780px) {
            float: right;
          }
        }
      }
      & dt {
        width: 100%;
        @media all and (min-width:780px) {
          width: 50%;
        }
        & .ttl__h3--txt {
          @media all and (min-width:780px) {
            margin-top: 45px;
          }
        }
      }
      & dd {
        width: 100%;
        margin-top: 30px;
        @media all and (min-width:780px) {
          width: 50%;
          margin-top: 0
        }
      }
    }
  }

}

.box {
  &_01 {
    background: wh(0.7);
    border: 3px solid bk(0.3);
    padding: 5%;
    @media all and (min-width:650px) {
      padding: 4%;
    }
    @media all and (min-width:800px) {
      padding: 2%;
    }
    &__ttl {
      color: sub03(1);
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 5px;
      & svg {
        width: 48px;
        height: 35px;
        fill: bk(0.5);
        vertical-align: -7px;
        padding-right: 7px;
      }
    }
    &__txt {
      font-size: 1.6rem;
      line-height: 1.5;
      @media all and (min-width:800px) {
        font-size: 1.8rem;
      }
    }
  }
}

.sbi-screenreader { display: none; }