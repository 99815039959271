// sub pages settings

$subpgList: service-general,service-bigrefuse,service-infections,service-industrial,about,news,faq,privacy,contact;

// @extend
%list-indent01 {
  & li {
    text-indent: -1rem;
    padding-left: 1rem;
    line-height: 1.4;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
%list-indent02 {
  & li {
    text-indent: -2rem;
    padding-left: 2rem;
    line-height: 1.4;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

%list-indent03 {
  & li {
    text-indent: -3rem;
    padding-left: 3rem;
    line-height: 1.4;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

$set_prefix: -webkit-, -moz-, -ms-, -o-, '';
%transition-all-linear {
  @each $prefix in $set_prefix {
    #{$prefix}transition: all 0.1s linear;
  }
}

%yu-go { font-family:"游ゴシック体", "Yu Gothic", YuGothic, "Lucida Grande","segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Verdana", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
%meiryo { font-family:"Lucida Grande","segoe UI","メイリオ", Meiryo, "Verdana","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
%yu-min { font-family: "游明朝","YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif; }

%josef { font-family: 'Josefin Slab', "游ゴシック体", "Yu Gothic", YuGothic, "Lucida Grande","segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Verdana", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
%didot { font-family: 'GFS Didot', serif; }
%hannari { font-family: "Hannari", "游明朝","YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif; & span {letter-spacing: -0.85rem;} }

@font-face {
    font-family: 'din';
    src: url('/assets/fonts/dinbold.woff2') format('woff2'),
         url('/assets/fonts/dinbold.woff') format('woff'),
         url('/assets/fonts/dinbold.ttf') format('truetype'),
         url('/assets/fonts/dinbold.svg#dinbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'biko_b';
    src: url('/assets/fonts/biko_bold-webfont.eot');
    src: url('/assets/fonts/biko_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/biko_bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/biko_bold-webfont.woff') format('woff'),
         url('/assets/fonts/biko_bold-webfont.ttf') format('truetype'),
         url('/assets/fonts/biko_bold-webfont.svg#bikobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'biko_r';
    src: url('/assets/fonts/biko_regular-webfont.eot');
    src: url('/assets/fonts/biko_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/biko_regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/biko_regular-webfont.woff') format('woff'),
         url('/assets/fonts/biko_regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/biko_regular-webfont.svg#bikoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

%clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

%hover {
  filter:alpha(opacity=100);
  opacity:1;
  @extend %transition-all-linear;
  &:hover, &:focus {
    filter:alpha(opacity=70);
    opacity:0.7;
    @extend %transition-all-linear;
  }
}

//@include abridgement(行間,文字数,表示したい行数,#背景色);
@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}

// color settings

@function wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function base01($opacity) {
  @return rgba(227, 227, 227, $opacity); //#e3e3e3
}

@function effect01($opacity) {
  @return rgba(248, 224, 0, $opacity); //#f8e000 yellow
}
@function effect02($opacity) {
  @return rgba(146, 130, 68, $opacity); //#928244 gold
}

@function sub01($opacity) {
  @return rgba(64, 64, 64, $opacity); //#404040
}
@function sub02($opacity) {
  @return rgba(128, 128, 128, $opacity); //#808080
}
@function sub03($opacity) {
  @return rgba(247, 147, 39, $opacity); //#f7931e
}
@function sub04($opacity) {
  @return rgba(255, 225, 0, $opacity); //#ffe100
}
@function sub05($opacity) {
  @return rgba(64, 64, 64, $opacity); //#404040
}

@function txt01($opacity) {
  @return rgba(34, 34, 34, $opacity); //#222
}

// btn settings
%btn_size_1 {
  width: 100%;
  @media all and (min-width:600px) {
    width: 300px;
  }
}
%btn_size_2 {
  width: 100%;
  @media all and (min-width:600px) {
    width: 386px;
  }
}
%btn_size_3 {
  width: 100%;
  @media all and (min-width:600px) {
    width: 156px;
  }
}
$svg_size: 11;
.arrow {
  width: $svg_size + px;
  height: $svg_size + px;
  fill: bk(1);
}
// end btn settings

@mixin btn($status) {
  clear: both;
  text-align: center;
  margin: 60px auto 0;
  & a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: effect01(1);
    &:hover, &:focus {
      background: effect02(1);
      @extend %transition-all-linear;
      & .btn__txt--en, .btn__txt--ja {
        color: wh(1);
        @extend %transition-all-linear;
      }
      & .arrow {
        fill: wh(1);
        @extend %transition-all-linear;
      }
    }
    & span {
      width: 90%;
      display: inline-block;
    }
    & .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto;
      @media all and (min-width:600px) {
        right: 30px;
      }
    }
    & .btn__txt {
      line-height: 1.2;
      &--en {
        @extend %josef;
        font-size: 2.6rem;
        font-weight: 600;
      }
      &--ja {
        font-size: 1.2rem;
        color: effect02(1);
      }
    }
  }
  @if $status == 1 { // s
    @extend %btn_size_1;
    & .btn__txt {
      padding: 8px 0 4px 0;
      &--en {
        font-size: 2rem !important;
        font-weight: 700;
      }
    }
  } @else if $status == 2 { // m
    @extend %btn_size_2;
    & a {
      border: 1px solid bk(1);
    }
    & .btn__txt {
      padding: 10px 0 14px 0;
    }
  } @else if $status == 22 { //m2
    & .btn__txt {
      &--en {
        color: wh(1);
      }
      &--ja {
        color: effect01(1) !important;
      }
    }
    & .arrow {
      fill: wh(1);
    }
    & a {
      border-color : wh(1) !important;
      background: bk(0.4) !important;
      &:hover, &:focus {
        background: bk(1) !important;
        @extend %transition-all-linear;
      }
    }
  } @else if $status == 3 { // m
    @extend %btn_size_3;
    margin: 0 auto 0;

    & a {
      border-style: none;
      border-radius: 100vh;

      & .btn__txt {  
        &--ja {
          font-size: 1.3rem;
          color: bk(1);
        }
      }

      & .arrow {
        position: absolute;
        right: 5%;
        top: 0;
        bottom: 0;
        width: 7.4px;
        height: 12px;
        margin: auto;
        @media all and (min-width:600px) {
          right: 20px;
        }
      }
    }
    & .btn__txt {
      padding: 3px 10px 4px 0;
    }
  } @else if $status == 4 { // m3
    @extend %btn_size_2;

    & a {
      border: 3px solid sub04(1);
      border-radius: 100vh;
      background: sub05(1) !important;
      @extend %transition-all-linear;

      & .btn__txt {
        padding: 20px 0 22px 0;
  
        &--ja {
          @extend %yu-go;
          font-size: 1.62rem;  //1.8 * 0.9 = 1.62
          font-weight: bold;
          color: sub04(1) !important;
          @extend %transition-all-linear;

          @media all and (min-width:600px) {
            font-size: 1.8rem;
          }
        }
      }

      & .arrow {
        fill: wh(1);
        @extend %transition-all-linear;
      }
      
      &:hover, &:focus {
        border-color: bk(1);
        background: sub04(1) !important;
        @extend %transition-all-linear;

        & .btn__txt {    
          &--ja {
            color: sub05(1) !important;
            @extend %transition-all-linear;
          }
        }

        & .arrow {
          fill: bk(1);
          @extend %transition-all-linear;
        }
      }
    }
  }
}

// btn
.btn {
  &__xs {
    @include btn(3);
  }
  &__s {
    @include btn(1);
  }
  &__m {
    @include btn(2);
  }
  &__m2 {
    @include btn(2);
    @include btn(22);
  }
  &__m3 {
    @include btn(4);
  }
}