@charset "UTF-8";
.tbl_02 td ul li {
  text-indent: -2rem;
  padding-left: 2rem;
  line-height: 1.4;
  margin-bottom: 5px; }
  .tbl_02 td ul li:last-child {
    margin-bottom: 0; }

.brand__item__a, .media__btn__item a:hover, .media__btn__item a:focus, .brand__item__a:hover, .brand__item__a:focus, .btn__xs a:hover, .btn__xs a:focus, .btn__xs a:hover .btn__txt--en, .btn__xs a:hover .btn__txt--ja, .btn__xs a:focus .btn__txt--en, .btn__xs a:focus .btn__txt--ja, .btn__xs a:hover .arrow, .btn__xs a:focus .arrow, .btn__s a:hover, .btn__s a:focus, .btn__s a:hover .btn__txt--en, .btn__s a:hover .btn__txt--ja, .btn__s a:focus .btn__txt--en, .btn__s a:focus .btn__txt--ja, .btn__s a:hover .arrow, .btn__s a:focus .arrow, .btn__m a:hover, .btn__m a:focus, .btn__m a:hover .btn__txt--en, .btn__m a:hover .btn__txt--ja, .btn__m a:focus .btn__txt--en, .btn__m a:focus .btn__txt--ja, .btn__m a:hover .arrow, .btn__m a:focus .arrow, .btn__m2 a:hover, .btn__m2 a:focus, .btn__m2 a:hover .btn__txt--en, .btn__m2 a:hover .btn__txt--ja, .btn__m2 a:focus .btn__txt--en, .btn__m2 a:focus .btn__txt--ja, .btn__m2 a:hover .arrow, .btn__m2 a:focus .arrow, .btn__m3 a:hover, .btn__m3 a:focus, .btn__m3 a:hover .btn__txt--en, .btn__m3 a:hover .btn__txt--ja, .btn__m3 a:focus .btn__txt--en, .btn__m3 a:focus .btn__txt--ja, .btn__m3 a:hover .arrow, .btn__m3 a:focus .arrow, .btn__m3 a, .btn__m3 a .btn__txt--ja, .btn__m3 a .arrow, .hover, .sns_fix .sns__icon:hover, .sns_fix .sns__icon:focus, .hover:hover, .hover:focus, .open .gnav__menuBtn__icon, .open .container__body, .open .container__body .header__logo__a svg, .footer__linkjd a:hover, .footer__linkjd a:focus, .gnav__body__item a:hover, .gnav__body__item a:focus, .key_salons__salon a:hover, .key_salons__salon a:focus, .media__tile:hover, .media__tile:focus {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear; }

.btn__m3 a .btn__txt--ja, body, textarea, .ttl__h2--txt span {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Lucida Grande","segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Verdana", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.btn__xs a .btn__txt--en, .btn__s a .btn__txt--en, .btn__m a .btn__txt--en, .btn__m2 a .btn__txt--en, .btn__m3 a .btn__txt--en, .footer__linkjd a, .media__txt--date, .news__h3--date, .media__txt--excerpt span, .media__txt--nm_en, .media__btn__item--txt, .tl__dt--time {
  font-family: 'Josefin Slab', "游ゴシック体", "Yu Gothic", YuGothic, "Lucida Grande","segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Verdana", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.ttl__h2--txt, .ttl__h3--txt span, .ttl__h4--en {
  font-family: 'GFS Didot', serif; }

.ttl__h4--ja {
  font-family: "Hannari", "游明朝","YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif; }
  .ttl__h4--ja span {
    letter-spacing: -0.85rem; }

@font-face {
  font-family: 'din';
  src: url("/assets/fonts/dinbold.woff2") format("woff2"), url("/assets/fonts/dinbold.woff") format("woff"), url("/assets/fonts/dinbold.ttf") format("truetype"), url("/assets/fonts/dinbold.svg#dinbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'biko_b';
  src: url("/assets/fonts/biko_bold-webfont.eot");
  src: url("/assets/fonts/biko_bold-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/biko_bold-webfont.woff2") format("woff2"), url("/assets/fonts/biko_bold-webfont.woff") format("woff"), url("/assets/fonts/biko_bold-webfont.ttf") format("truetype"), url("/assets/fonts/biko_bold-webfont.svg#bikobold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'biko_r';
  src: url("/assets/fonts/biko_regular-webfont.eot");
  src: url("/assets/fonts/biko_regular-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/biko_regular-webfont.woff2") format("woff2"), url("/assets/fonts/biko_regular-webfont.woff") format("woff"), url("/assets/fonts/biko_regular-webfont.ttf") format("truetype"), url("/assets/fonts/biko_regular-webfont.svg#bikoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.brand__item__a, .media__btn__item a:hover, .media__btn__item a:focus {
  filter: alpha(opacity=100);
  opacity: 1; }
  .brand__item__a:hover, .media__btn__item a:hover, .brand__item__a:focus, .media__btn__item a:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.btn__s {
  width: 100%; }
  @media all and (min-width: 600px) {
    .btn__s {
      width: 300px; } }

.btn__m, .btn__m2, .btn__m3 {
  width: 100%; }
  @media all and (min-width: 600px) {
    .btn__m, .btn__m2, .btn__m3 {
      width: 386px; } }

.btn__xs {
  width: 100%; }
  @media all and (min-width: 600px) {
    .btn__xs {
      width: 156px; } }

.arrow {
  width: 11px;
  height: 11px;
  fill: black; }

.btn__xs {
  clear: both;
  text-align: center;
  margin: 60px auto 0;
  margin: 0 auto 0; }
  .btn__xs a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__xs a:hover, .btn__xs a:focus {
      background: #928244; }
      .btn__xs a:hover .btn__txt--en, .btn__xs a:hover .btn__txt--ja, .btn__xs a:focus .btn__txt--en, .btn__xs a:focus .btn__txt--ja {
        color: white; }
      .btn__xs a:hover .arrow, .btn__xs a:focus .arrow {
        fill: white; }
    .btn__xs a span {
      width: 90%;
      display: inline-block; }
    .btn__xs a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__xs a .arrow {
          right: 30px; } }
    .btn__xs a .btn__txt {
      line-height: 1.2; }
      .btn__xs a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__xs a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__xs a {
    border-style: none;
    border-radius: 100vh; }
    .btn__xs a .btn__txt--ja {
      font-size: 1.3rem;
      color: black; }
    .btn__xs a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      width: 7.4px;
      height: 12px;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__xs a .arrow {
          right: 20px; } }
  .btn__xs .btn__txt {
    padding: 3px 10px 4px 0; }

.btn__s {
  clear: both;
  text-align: center;
  margin: 60px auto 0; }
  .btn__s a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__s a:hover, .btn__s a:focus {
      background: #928244; }
      .btn__s a:hover .btn__txt--en, .btn__s a:hover .btn__txt--ja, .btn__s a:focus .btn__txt--en, .btn__s a:focus .btn__txt--ja {
        color: white; }
      .btn__s a:hover .arrow, .btn__s a:focus .arrow {
        fill: white; }
    .btn__s a span {
      width: 90%;
      display: inline-block; }
    .btn__s a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__s a .arrow {
          right: 30px; } }
    .btn__s a .btn__txt {
      line-height: 1.2; }
      .btn__s a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__s a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__s .btn__txt {
    padding: 8px 0 4px 0; }
    .btn__s .btn__txt--en {
      font-size: 2rem !important;
      font-weight: 700; }

.btn__m {
  clear: both;
  text-align: center;
  margin: 60px auto 0; }
  .btn__m a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__m a:hover, .btn__m a:focus {
      background: #928244; }
      .btn__m a:hover .btn__txt--en, .btn__m a:hover .btn__txt--ja, .btn__m a:focus .btn__txt--en, .btn__m a:focus .btn__txt--ja {
        color: white; }
      .btn__m a:hover .arrow, .btn__m a:focus .arrow {
        fill: white; }
    .btn__m a span {
      width: 90%;
      display: inline-block; }
    .btn__m a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__m a .arrow {
          right: 30px; } }
    .btn__m a .btn__txt {
      line-height: 1.2; }
      .btn__m a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__m a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__m a {
    border: 1px solid black; }
  .btn__m .btn__txt {
    padding: 10px 0 14px 0; }

.btn__m2 {
  clear: both;
  text-align: center;
  margin: 60px auto 0;
  clear: both;
  text-align: center;
  margin: 60px auto 0; }
  .btn__m2 a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__m2 a:hover, .btn__m2 a:focus {
      background: #928244; }
      .btn__m2 a:hover .btn__txt--en, .btn__m2 a:hover .btn__txt--ja, .btn__m2 a:focus .btn__txt--en, .btn__m2 a:focus .btn__txt--ja {
        color: white; }
      .btn__m2 a:hover .arrow, .btn__m2 a:focus .arrow {
        fill: white; }
    .btn__m2 a span {
      width: 90%;
      display: inline-block; }
    .btn__m2 a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__m2 a .arrow {
          right: 30px; } }
    .btn__m2 a .btn__txt {
      line-height: 1.2; }
      .btn__m2 a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__m2 a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__m2 a {
    border: 1px solid black; }
  .btn__m2 .btn__txt {
    padding: 10px 0 14px 0; }
  .btn__m2 a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__m2 a:hover, .btn__m2 a:focus {
      background: #928244; }
      .btn__m2 a:hover .btn__txt--en, .btn__m2 a:hover .btn__txt--ja, .btn__m2 a:focus .btn__txt--en, .btn__m2 a:focus .btn__txt--ja {
        color: white; }
      .btn__m2 a:hover .arrow, .btn__m2 a:focus .arrow {
        fill: white; }
    .btn__m2 a span {
      width: 90%;
      display: inline-block; }
    .btn__m2 a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__m2 a .arrow {
          right: 30px; } }
    .btn__m2 a .btn__txt {
      line-height: 1.2; }
      .btn__m2 a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__m2 a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__m2 .btn__txt--en {
    color: white; }
  .btn__m2 .btn__txt--ja {
    color: #f8e000 !important; }
  .btn__m2 .arrow {
    fill: white; }
  .btn__m2 a {
    border-color: white !important;
    background: rgba(0, 0, 0, 0.4) !important; }
    .btn__m2 a:hover, .btn__m2 a:focus {
      background: black !important; }

.btn__m3 {
  clear: both;
  text-align: center;
  margin: 60px auto 0; }
  .btn__m3 a {
    width: 100%;
    display: table;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;
    background: #f8e000; }
    .btn__m3 a:hover, .btn__m3 a:focus {
      background: #928244; }
      .btn__m3 a:hover .btn__txt--en, .btn__m3 a:hover .btn__txt--ja, .btn__m3 a:focus .btn__txt--en, .btn__m3 a:focus .btn__txt--ja {
        color: white; }
      .btn__m3 a:hover .arrow, .btn__m3 a:focus .arrow {
        fill: white; }
    .btn__m3 a span {
      width: 90%;
      display: inline-block; }
    .btn__m3 a .arrow {
      position: absolute;
      right: 5%;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media all and (min-width: 600px) {
        .btn__m3 a .arrow {
          right: 30px; } }
    .btn__m3 a .btn__txt {
      line-height: 1.2; }
      .btn__m3 a .btn__txt--en {
        font-size: 2.6rem;
        font-weight: 600; }
      .btn__m3 a .btn__txt--ja {
        font-size: 1.2rem;
        color: #928244; }
  .btn__m3 a {
    border: 3px solid #ffe100;
    border-radius: 100vh;
    background: #404040 !important; }
    .btn__m3 a .btn__txt {
      padding: 20px 0 22px 0; }
      .btn__m3 a .btn__txt--ja {
        font-size: 1.62rem;
        font-weight: bold;
        color: #ffe100 !important; }
        @media all and (min-width: 600px) {
          .btn__m3 a .btn__txt--ja {
            font-size: 1.8rem; } }
    .btn__m3 a .arrow {
      fill: white; }
    .btn__m3 a:hover, .btn__m3 a:focus {
      border-color: black;
      background: #ffe100 !important; }
      .btn__m3 a:hover .btn__txt--ja, .btn__m3 a:focus .btn__txt--ja {
        color: #404040 !important; }
      .btn__m3 a:hover .arrow, .btn__m3 a:focus .arrow {
        fill: black; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font: 62.5%; }

img {
  vertical-align: bottom;
  width: 100%; }

html {
  font-size: 62.5%;
  line-height: 1.8; }

body {
  *font-size: small;
  *font: x-small;
  -webkit-text-size-adjust: none; }

input, select {
  font-family: inherit;
  font-size: 100%; }

select, input, button, textarea {
  font: 99% sans-serif; }

pre, code, kbd, samp, tt {
  *font-size: 108%;
  line-height: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  font: 100%; }

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

em {
  font-style: normal; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

figure {
  display: block;
  margin: 0;
  padding: 0; }

svg {
  line-height: 0; }

#defs {
  display: none; }

body {
  color: #222222;
  background: #e3e3e3;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2; }

a {
  color: rgba(34, 34, 34, 0.8);
  text-decoration: none; }

::selection, ::-moz-selection {
  background: #928244; }

.hover, .sns_fix .sns__icon:hover, .sns_fix .sns__icon:focus {
  filter: alpha(opacity=100);
  opacity: 1; }
  .hover:hover, .sns_fix .sns__icon:hover, .hover:focus, .sns_fix .sns__icon:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.m_0 {
  margin: 0px !important; }

.mt_0 {
  margin-top: 0px !important; }

.mb_0 {
  margin-bottom: 0px !important; }

.ml_0 {
  margin-left: 0px !important; }

.mr_0 {
  margin-right: 0px !important; }

.p_0 {
  padding: 0px !important; }

.pt_0 {
  padding-top: 0px !important; }

.pb_0 {
  padding-bottom: 0px !important; }

.pl_0 {
  padding-left: 0px !important; }

.pr_0 {
  padding-right: 0px !important; }

.m_5 {
  margin: 5px !important; }

.mt_5 {
  margin-top: 5px !important; }

.mb_5 {
  margin-bottom: 5px !important; }

.ml_5 {
  margin-left: 5px !important; }

.mr_5 {
  margin-right: 5px !important; }

.p_5 {
  padding: 5px !important; }

.pt_5 {
  padding-top: 5px !important; }

.pb_5 {
  padding-bottom: 5px !important; }

.pl_5 {
  padding-left: 5px !important; }

.pr_5 {
  padding-right: 5px !important; }

.m_10 {
  margin: 10px !important; }

.mt_10 {
  margin-top: 10px !important; }

.mb_10 {
  margin-bottom: 10px !important; }

.ml_10 {
  margin-left: 10px !important; }

.mr_10 {
  margin-right: 10px !important; }

.p_10 {
  padding: 10px !important; }

.pt_10 {
  padding-top: 10px !important; }

.pb_10 {
  padding-bottom: 10px !important; }

.pl_10 {
  padding-left: 10px !important; }

.pr_10 {
  padding-right: 10px !important; }

.m_15 {
  margin: 15px !important; }

.mt_15 {
  margin-top: 15px !important; }

.mb_15 {
  margin-bottom: 15px !important; }

.ml_15 {
  margin-left: 15px !important; }

.mr_15 {
  margin-right: 15px !important; }

.p_15 {
  padding: 15px !important; }

.pt_15 {
  padding-top: 15px !important; }

.pb_15 {
  padding-bottom: 15px !important; }

.pl_15 {
  padding-left: 15px !important; }

.pr_15 {
  padding-right: 15px !important; }

.m_20 {
  margin: 20px !important; }

.mt_20 {
  margin-top: 20px !important; }

.mb_20 {
  margin-bottom: 20px !important; }

.ml_20 {
  margin-left: 20px !important; }

.mr_20 {
  margin-right: 20px !important; }

.p_20 {
  padding: 20px !important; }

.pt_20 {
  padding-top: 20px !important; }

.pb_20 {
  padding-bottom: 20px !important; }

.pl_20 {
  padding-left: 20px !important; }

.pr_20 {
  padding-right: 20px !important; }

.m_25 {
  margin: 25px !important; }

.mt_25 {
  margin-top: 25px !important; }

.mb_25 {
  margin-bottom: 25px !important; }

.ml_25 {
  margin-left: 25px !important; }

.mr_25 {
  margin-right: 25px !important; }

.p_25 {
  padding: 25px !important; }

.pt_25 {
  padding-top: 25px !important; }

.pb_25 {
  padding-bottom: 25px !important; }

.pl_25 {
  padding-left: 25px !important; }

.pr_25 {
  padding-right: 25px !important; }

.m_30 {
  margin: 30px !important; }

.mt_30 {
  margin-top: 30px !important; }

.mb_30 {
  margin-bottom: 30px !important; }

.ml_30 {
  margin-left: 30px !important; }

.mr_30 {
  margin-right: 30px !important; }

.p_30 {
  padding: 30px !important; }

.pt_30 {
  padding-top: 30px !important; }

.pb_30 {
  padding-bottom: 30px !important; }

.pl_30 {
  padding-left: 30px !important; }

.pr_30 {
  padding-right: 30px !important; }

.spacer_15 {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .spacer_15 {
      margin-bottom: 30px !important; } }

.spacer_30, .ttl__h3--txt, .ttl__h4--en, .news__h3--ttl {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .spacer_30, .ttl__h3--txt, .ttl__h4--en, .news__h3--ttl {
      margin-bottom: 45px !important; } }

.spacer_45 {
  margin-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .spacer_45 {
      margin-bottom: 60px !important; } }

.spacer_60 {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .spacer_60 {
      margin-bottom: 75px !important; } }

.spacer_75 {
  margin-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .spacer_75 {
      margin-bottom: 90px !important; } }

.spacer_90 {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .spacer_90 {
      margin-bottom: 105px !important; } }

.spacer_105 {
  margin-bottom: 105px !important; }
  @media all and (min-width: 800px) {
    .spacer_105 {
      margin-bottom: 120px !important; } }

.spacer_120 {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .spacer_120 {
      margin-bottom: 135px !important; } }

.spacer_135 {
  margin-bottom: 135px !important; }
  @media all and (min-width: 800px) {
    .spacer_135 {
      margin-bottom: 150px !important; } }

.spacer_60-30 {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .spacer_60-30 {
      margin-bottom: 60px !important; } }

.left {
  float: left !important; }

.right {
  float: right !important; }

.container {
  width: 100%;
  padding: 30px 0;
  background: #e3e3e3; }
  @media all and (min-width: 800px) {
    .container {
      padding: 60px 0; } }
  .container--bg_frame {
    background: white;
    border: 15px solid #e3e3e3;
    box-sizing: border-box; }
  .container--padding0 {
    padding: 0; }
  .container__body {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden; }
    @media all and (min-width: 1120px) {
      .container__body {
        width: 1000px; } }

.external_link_icon {
  fill: #f8e000;
  width: 14px;
  height: 14px;
  padding: 0 5px; }

.ttl__h2 {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
  position: relative;
  font-weight: bold;
  margin: 0 0 60px 0; }
  .ttl__h2--txt {
    font-size: 4.5rem;
    font-style: italic;
    line-height: 0.75; }
    .ttl__h2--txt span {
      font-size: 1.4rem;
      line-height: 1;
      font-style: normal; }
  .ttl__h2__svg {
    display: block;
    margin: 0 auto 10px; }
    .ttl__h2__svg_news {
      width: 98px;
      height: 33px; }
    .ttl__h2__svg_salon {
      width: 102px;
      height: 34px; }
    .ttl__h2__svg_recruit {
      width: 225px;
      height: 55px; }
      .ttl__h2__svg_recruit_02 {
        width: 122px;
        height: 34px; }
    .ttl__h2__svg_ig {
      width: 199px;
      height: 47px; }
    .ttl__h2__svg_about {
      width: 158px;
      height: 34px; }
  .ttl__h2:after {
    content: '';
    width: 180px;
    display: inline-block;
    border-bottom: 1px solid black;
    position: absolute;
    left: 0;
    right: 0;
    top: 65%;
    margin: auto; }

.ttl__h3 {
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  clear: both;
  margin-bottom: 30px; }
  .ttl__h3__svg {
    margin-top: 10px; }
    .ttl__h3__svg_jd {
      width: 133px;
      height: 36px; }
    .ttl__h3__svg_cl {
      width: 190px;
      height: 36px; }
  .ttl__h3__nm {
    font-size: 1.5rem;
    font-weight: bold;
    vertical-align: 8px;
    padding-left: 5px; }
  .ttl__h3--txt {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.5; }
    .ttl__h3--txt span {
      font-size: 2.8rem; }

.ttl__h4--en {
  text-align: center;
  font-size: 2.4rem; }

.ttl__h4--ja {
  font-size: 4rem;
  text-align: left;
  margin-bottom: 30px;
  line-height: 1.4; }
  @media all and (min-width: 650px) {
    .ttl__h4--ja {
      font-size: 4.5rem; } }
  .ttl__h4--ja__icon_hito {
    width: 22px;
    height: 47px;
    vertical-align: -5px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_hito {
        width: 30px;
        height: 60px;
        vertical-align: -7px; } }
  .ttl__h4--ja__icon_flag {
    width: 35px;
    height: 37px;
    vertical-align: -4px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_flag {
        width: 43px;
        height: 45px;
        vertical-align: -3px; } }
  .ttl__h4--ja__icon_house {
    width: 47px;
    height: 47px;
    vertical-align: -3px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_house {
        width: 60px;
        height: 60px;
        vertical-align: -7px; } }
  .ttl__h4--ja__icon_eiffel {
    width: 33px;
    height: 50px;
    vertical-align: -7px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_eiffel {
        width: 40px;
        height: 60px;
        vertical-align: -7px; } }
  .ttl__h4--ja__icon_clock {
    width: 40px;
    height: 40px;
    vertical-align: -6px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_clock {
        width: 45px;
        height: 45px;
        vertical-align: -6px; } }
  .ttl__h4--ja__icon_study {
    width: 33px;
    height: 19px;
    vertical-align: -3px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_study {
        width: 33px;
        height: 19px;
        vertical-align: -3px; } }
  .ttl__h4--ja__icon_cut {
    width: 33px;
    height: 35px;
    vertical-align: -8px; }
    @media all and (min-width: 650px) {
      .ttl__h4--ja__icon_cut {
        width: 33px;
        height: 35px;
        vertical-align: -8px; } }

.sub .ttl__h2 {
  margin-top: 90px; }
  @media all and (min-width: 800px) {
    .sub .ttl__h2 {
      margin-top: 120px; } }

.header {
  width: 100%;
  height: 60px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }
  .header__logo {
    height: 60px;
    text-align: center;
    line-height: 1; }
    .header__logo svg {
      margin-top: 9px;
      width: 136px;
      height: 40px; }
  .header .container__body {
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid black;
    box-sizing: border-box;
    position: relative;
    z-index: 1100; }

.index .header .container__body {
  display: none; }

.index .header.open .container__body {
  display: block !important; }

.visible .container__body {
  display: block !important; }

.open .gnav__menuBtn__icon {
  fill: white; }

.open .container__body {
  display: block;
  border-bottom: 1px solid white; }
  .open .container__body .header__logo__a svg {
    fill: white; }

.footer {
  padding: 0; }
  .footer .container__body {
    padding: 15px 0;
    width: 90%;
    border-top: 1px solid black; }
  .footer__cp {
    text-align: center;
    font-size: 1.1rem; }
  .footer__linkjd {
    text-align: center;
    margin-bottom: 10px; }
    .footer__linkjd a {
      background: rgba(255, 255, 255, 0.5);
      color: black;
      border: 1px solid black;
      padding: 9px 10px 6px 12px;
      line-height: 1;
      font-weight: bold;
      box-sizing: border-box; }
      .footer__linkjd a:hover, .footer__linkjd a:focus {
        background: white; }
    .footer__linkjd .arrow {
      width: 8px;
      height: 10px;
      vertical-align: -1px; }

#map_area {
  height: 250px; }

.gnav {
  width: 100%;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 1000; }
  .gnav__body {
    padding-top: 65px;
    display: none;
    margin: 0 auto;
    overflow: hidden; }
    .gnav__body__item {
      display: block; }
      .gnav__body__item:nth-child(2n) {
        background: rgba(0, 0, 0, 0.2); }
      .gnav__body__item a {
        width: 90%;
        display: block;
        padding: 3px 5%;
        color: white;
        text-align: center; }
        .gnav__body__item a:hover, .gnav__body__item a:focus {
          filter: alpha(opacity=70);
          opacity: 0.7; }
        .gnav__body__item a.current {
          color: #f8e000; }
      .gnav__body__item.child {
        text-indent: 1rem;
        background: rgba(0, 0, 0, 0.5);
        font-size: 85%; }
        .gnav__body__item.child a {
          color: rgba(255, 255, 255, 0.7); }

.gnav__menuBtn {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 15px;
  right: 5%;
  z-index: 2000;
  cursor: pointer; }
  .gnav__menuBtn__icon {
    display: block;
    width: 20px;
    height: 20px;
    fill: #222222; }

.postarea h4 {
  font-size: 2rem;
  font-weight: bold; }

.postarea h5 {
  font-size: 1.7rem;
  font-weight: bold; }

.postarea img {
  width: 100%;
  height: inherit; }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .postarea img.horizontally-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .postarea img.horizontally-S {
      width: inherit; } }

.pager {
  clear: both;
  text-align: center;
  margin-bottom: 30px; }
  @media all and (min-width: 800px) {
    .pager {
      margin-bottom: 60px; } }
  .pager span {
    margin: 0 2px; }
    .pager span.current {
      background: #404040;
      color: white;
      padding: 5px 9px;
      margin: 0 2px; }
      .pager span.current:hover, .pager span.current:focus {
        background: #404040; }
  .pager a {
    padding: 5px 9px;
    margin: 0 5px;
    color: #222222;
    text-decoration: none; }
    .pager a:hover, .pager a:focus {
      color: #f8e000; }
    .pager a.page {
      background: base02(0.8); }
      .pager a.page:hover, .pager a.page:focus {
        background: #928244;
        color: white; }
  .pager .page-numbers {
    background: base02(0.8); }
    .pager .page-numbers:hover, .pager .page-numbers:focus {
      background: #928244;
      color: white; }
    .pager .page-numbers.next, .pager .page-numbers.prev {
      background: transparent; }
      .pager .page-numbers.next:hover, .pager .page-numbers.next:focus, .pager .page-numbers.prev:hover, .pager .page-numbers.prev:focus {
        background: transparent;
        color: #928244; }

.formtbl {
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 800px) {
    .formtbl {
      width: 700px; } }
  .formtbl th {
    width: 100%;
    text-align: left;
    background: #f79327;
    color: white;
    padding: 15px;
    font-weight: normal;
    box-sizing: border-box;
    vertical-align: top;
    display: block; }
    @media all and (min-width: 650px) {
      .formtbl th {
        width: 25%;
        display: table-cell;
        border-right: 5px solid white;
        border-bottom: 5px solid white; } }
  .formtbl td {
    border-bottom: 5px solid white;
    background: rgba(64, 64, 64, 0.24);
    padding: 15px;
    box-sizing: border-box;
    display: block; }
    @media all and (min-width: 650px) {
      .formtbl td {
        display: table-cell; } }
  .formtbl input, .formtbl textarea {
    border: none;
    width: 94%;
    padding: 10px 3%; }
    .formtbl input.check, .formtbl textarea.check {
      width: inherit; }
  .formtbl #rule {
    text-indent: -2.5rem;
    padding-left: 2.5rem; }
    .formtbl #rule a {
      color: #f8e000;
      text-decoration: underline; }
      .formtbl #rule a:hover, .formtbl #rule a:focus {
        text-decoration: none; }
  .formtbl .error {
    color: #f8e000;
    font-size: 80%; }

.btn__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100% !important;
  height: 70px !important;
  border: none;
  background: transparent;
  cursor: pointer; }

.form__btns {
  margin: 45px auto 0;
  overflow: hidden; }
  @media all and (min-width: 650px) {
    .form__btns {
      width: 530px; } }
  .form__btns .btn__small {
    margin: 0;
    clear: none; }
  @media all and (min-width: 650px) {
    .form__btns .btn__back {
      float: left; } }
  @media all and (min-width: 650px) {
    .form__btns .btn__send {
      float: right; } }

.br_sp {
  display: inline; }
  @media all and (min-width: 650px) {
    .br_sp {
      display: none; } }

.sns {
  display: table;
  margin: 0 auto 5px; }
  @media all and (min-width: 800px) {
    .sns {
      display: none; } }
  .sns__icon {
    display: table-cell;
    padding: 0 5px 10px 5px; }
    .sns__icon a {
      display: block;
      line-height: 0; }
    .sns__icon svg {
      width: 30px;
      height: 30px;
      fill: black; }

.sns_fix {
  height: 75px;
  display: none;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 500; }
  @media all and (min-width: 800px) {
    .sns_fix {
      display: block; } }
  .sns_fix .sns__icon {
    display: block;
    padding: 0 15px 15px 0; }

.key {
  position: relative;
  z-index: 1;
  padding: 0; }
  .key--index {
    width: 100%;
    overflow: hidden;
    text-align: center; }
    .key--index__grlogo {
      position: absolute;
      left: 5%;
      bottom: 10px; }
      @media all and (min-width: 800px) {
        .key--index__grlogo {
          bottom: 30px; } }
      .key--index__grlogo svg {
        width: 250px;
        height: 73px; }
        @media all and (min-width: 800px) {
          .key--index__grlogo svg {
            width: 554px;
            height: 161px; } }
    .key--index__logo {
      text-align: center;
      margin-top: 40px; }
      @media all and (min-width: 560px) {
        .key--index__logo {
          margin-top: 100px;
          margin-bottom: 100px; } }
      .key--index__logo svg {
        width: 100%;
        height: 161px; }
        @media all and (min-width: 560px) {
          .key--index__logo svg {
            width: 554px;
            height: 161px; } }

.key_salons {
  width: 240px;
  margin: 0 auto;
  overflow: hidden; }
  @media all and (min-width: 560px) {
    .key_salons {
      width: 490px; } }
  .key_salons__salon a {
    width: 240px;
    height: 100px;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    margin-bottom: 10px; }
    @media all and (min-width: 560px) {
      .key_salons__salon a {
        margin-bottom: 0; } }
    .key_salons__salon a svg {
      display: block;
      margin: 0 auto; }
    .key_salons__salon a:hover, .key_salons__salon a:focus {
      background: black; }
  @media all and (min-width: 560px) {
    .key_salons__salon01 {
      float: left; } }
  .key_salons__salon01 svg {
    width: 182px;
    height: 48px;
    padding-top: 26px !important; }
  @media all and (min-width: 560px) {
    .key_salons__salon02 {
      float: right; } }
  .key_salons__salon02 svg {
    width: 182px;
    height: 35px;
    padding-top: 33px !important; }

.txt--lead {
  text-align: center;
  font-size: 2.2rem;
  line-height: 1.5; }
  @media all and (min-width: 650px) {
    .txt--lead {
      font-size: 3rem; } }
  .txt--lead_02 {
    font-size: 2rem;
    text-align: center; }

.txt--icon {
  text-indent: -40px;
  padding-left: 40px; }

.txt--left {
  text-align: left; }

.txt--center {
  text-align: center; }

.txt--narrow {
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 800px) {
    .txt--narrow {
      width: 700px; } }

.txt--ttl {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5); }

.txt--small {
  font-size: 1rem;
  line-height: 1.5; }

.txt__gr_01 {
  width: 100%; }
  @media all and (min-width: 650px) {
    .txt__gr_01 {
      width: 55.5%;
      float: right; } }

.fig--gr01 {
  padding-bottom: 30px; }
  @media all and (min-width: 650px) {
    .fig--gr01 {
      width: 40%;
      padding: 0 4.5% 60px 0;
      float: left; } }
  .fig--gr01_right {
    padding-bottom: 30px; }
    @media all and (min-width: 650px) {
      .fig--gr01_right {
        width: 40%;
        padding: 0 0 60px 4.5%;
        float: right; } }

.fig--gr02 {
  padding-bottom: 30px; }
  @media all and (min-width: 650px) {
    .fig--gr02 {
      width: 40%;
      padding: 0 4.5% 60px 0;
      float: left;
      overflow: hidden; } }
  .fig--gr02 img:first-child {
    float: left;
    margin-bottom: 30px; }

.hr {
  border-bottom: 1px solid black; }

.kome {
  text-indent: -1rem;
  padding-left: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media (min-width: 800px) {
    .anchor {
      top: -90px; } }

.tbl_01 {
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 800px) {
    .tbl_01 {
      width: 700px; } }
  .tbl_01 th {
    text-align: left;
    padding: 10px;
    line-height: 1.3;
    letter-spacing: -0.1rem;
    vertical-align: middle; }
    .tbl_01 th small {
      font-size: 1rem;
      font-weight: normal; }
  .tbl_01 td {
    width: 8rem;
    text-align: right;
    padding: 10px;
    font-weight: bold;
    vertical-align: middle; }
  .tbl_01 tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.tbl_02 {
  width: 100%;
  margin: 0 auto; }
  @media all and (min-width: 800px) {
    .tbl_02 {
      width: 700px; } }
  .tbl_02 th, .tbl_02 td {
    line-height: 1.4; }
    .tbl_02 th small, .tbl_02 td small {
      font-size: 1rem;
      font-weight: normal; }
  .tbl_02 th {
    width: 6rem;
    text-align: left;
    padding: 10px 10px;
    letter-spacing: -0.1rem;
    font-weight: normal;
    vertical-align: top; }
    @media all and (min-width: 800px) {
      .tbl_02 th {
        width: 15rem; } }
    .tbl_02 th small {
      font-size: 1rem;
      font-weight: normal; }
  .tbl_02 td {
    text-align: left;
    padding: 10px 10px;
    font-weight: normal;
    vertical-align: middle; }
  .tbl_02 tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.brand {
  width: 100%;
  display: block;
  background-color: transparent;
  overflow: hidden; }
  @media all and (min-width: 650px) {
    .brand {
      background-color: #f8e000; } }
  .brand__item {
    width: 100%;
    display: block;
    margin-bottom: 15px; }
    @media all and (min-width: 650px) {
      .brand__item {
        width: 50%;
        float: left;
        margin-bottom: 0; } }
    .brand__item__a {
      width: 75%;
      height: auto;
      display: block;
      padding: 50px 12.5%;
      background-color: white;
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat; }
      @media all and (min-width: 650px) {
        .brand__item__a {
          height: 290px; } }
    @media all and (min-width: 650px) {
      .brand__item--jd {
        background-image: url(../../assets/images/index/bg_jd.jpg); } }
    .brand__item--jd__logo__svg {
      width: 243px;
      height: 65px; }
      @media all and (max-width: 440px) {
        .brand__item--jd__logo__svg {
          width: 90%; } }
      @media all and (min-width: 650px) and (max-width: 750px) {
        .brand__item--jd__logo__svg {
          width: 90%; } }
    @media all and (min-width: 650px) {
      .brand__item--cl {
        background-image: url(../../assets/images/index/bg_clair.jpg); } }
    .brand__item--cl__logo__svg {
      width: 292px;
      height: 56px;
      padding: 4px 0 5px; }
      @media all and (max-width: 440px) {
        .brand__item--cl__logo__svg {
          width: 90%; } }
      @media all and (min-width: 650px) and (max-width: 890px) {
        .brand__item--cl__logo__svg {
          width: 90%; } }
    .brand__item__logo {
      width: 100%;
      height: auto;
      background: rgba(255, 255, 255, 0.85);
      position: relative; }
      @media all and (min-width: 650px) {
        .brand__item__logo {
          height: 100%; } }
      .brand__item__logo__svg {
        display: block;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto; }

.media__tile {
  width: 100%;
  margin-bottom: 30px; }
  @media all and (min-width: 800px) {
    .media__tile {
      width: 30.3%;
      padding: 1.5%;
      float: left; } }
  .media__tile_50 {
    width: 47%;
    padding: 1.5%;
    float: left;
    margin-bottom: 15px; }
    @media all and (min-width: 800px) {
      .media__tile_50 {
        width: 30.3%;
        padding: 1.5%;
        float: left; } }
    .media__tile_50:last-of-type {
      margin-bottom: 45px; }
  .media__tile a {
    display: block; }
  .media__tile:hover, .media__tile:focus {
    background: rgba(255, 255, 255, 0.3); }

.media__eyeImg, .media__eyeImg_50 {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../assets/images/common/thum.jpg); }
  .media__eyeImg_50 {
    height: 120px; }
  @media all and (min-width: 650px) {
    .media__eyeImg, .media__eyeImg_50 {
      height: 300px; } }
  @media all and (min-width: 800px) {
    .media__eyeImg, .media__eyeImg_50 {
      height: 200px; } }
  .media__eyeImg_yoshihiro {
    background-image: url(../../assets/images/salon/salon_yoshihiro_thum.jpg); }
  .media__eyeImg_omori {
    background-image: url(../../assets/images/salon/salon_omori_thum.jpg); }
  .media__eyeImg_atreomori {
    background-image: url(../../assets/images/salon/salon_atreomori_thum.jpg); }
  .media__eyeImg_kugahara {
    background-image: url(../../assets/images/salon/salon_kugahara_thum.jpg); }
  .media__eyeImg_kakinokizaka {
    background-image: url(../../assets/images/salon/salon_kakinokizaka_thum.jpg); }
  .media__eyeImg_atrekawasaki {
    background-image: url(../../assets/images/salon/salon_atrekawasaki_thum.jpg); }
  .media__eyeImg_musashishinjo {
    background-image: url(../../assets/images/salon/salon_musashishinjo_thum.jpg); }
  .media__eyeImg_tomioka {
    background-image: url(../../assets/images/salon/salon_tomioka_thum.jpg); }
  .media__eyeImg_hakuraku {
    background-image: url(../../assets/images/salon/salon_hakuraku_thum.jpg); }
  .media__eyeImg_umeyashiki {
    background-image: url(../../assets/images/salon/salon_umeyashiki_thum.jpg); }
  .media__eyeImg_omorimachi {
    background-image: url(../../assets/images/salon/salon_omorimachi_thum.jpg); }
  .media__eyeImg_nakanobe {
    background-image: url(../../assets/images/salon/salon_nakanobe_thum.jpg); }

.media__txt {
  margin-top: 20px; }
  .media__txt--date, .news__h3--date {
    color: #928244;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1; }
  .media__txt--ttl {
    height: 4rem;
    font-size: 1.7rem;
    font-weight: bold;
    overflow: hidden; }
  .media__txt--excerpt {
    font-size: 1.3rem; }
    .media__txt--excerpt span {
      color: #928244;
      font-weight: 400; }
  .media__txt--nm_en {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1; }
    @media all and (min-width: 650px) {
      .media__txt--nm_en {
        font-size: 1.85rem; } }
  .media__txt--nm_ja {
    font-size: 1.3rem;
    font-weight: bold; }
  .media__txt_short {
    display: block;
    margin-top: 0;
    text-align: center; }
    @media all and (min-width: 650px) {
      .media__txt_short {
        padding-top: 5px;
        display: table-cell;
        text-align: left; } }

.media__info {
  display: table;
  width: 90%;
  padding: 15px 5% 0; }

.media__btn {
  width: 114px;
  display: block;
  vertical-align: top;
  overflow: hidden;
  margin: 5px auto 0; }
  @media all and (min-width: 650px) {
    .media__btn {
      display: table-cell;
      margin: inherit;
      margin: 0 auto; } }
  .media__btn__item {
    float: left;
    margin-left: 7px; }
    .media__btn__item a {
      width: 50px;
      height: 50px;
      display: block;
      background: #404040;
      border-radius: 100px;
      text-align: center; }
    .media__btn__item--txt {
      color: #f8e000;
      line-height: 1;
      font-size: 1.1rem;
      padding-top: 14px; }
    .media__btn__item--icon {
      fill: gray;
      display: block;
      margin: 0 auto; }
      .media__btn__item--icon_list {
        width: 17px;
        height: 11px;
        margin-top: 4px; }
      .media__btn__item--icon_spot {
        width: 12px;
        height: 17px;
        margin-top: 1px; }

.media__reservation {
  width: 100%; }

.split {
  color: transparent;
  letter-spacing: -0.5rem; }

.index__recruit {
  background: url(../../assets/images/index/bg_recruit.jpg) right top no-repeat;
  background-size: cover; }
  .index__recruit .container__body {
    width: 80%;
    background: rgba(0, 0, 0, 0.45);
    padding: 5%;
    color: white; }
  .index__recruit .ttl__h2 {
    color: #f8e000; }
    .index__recruit .ttl__h2__svg {
      fill: #f8e000; }
    .index__recruit .ttl__h2:after {
      display: none; }

#ig .ttl__h2 {
  margin-bottom: 15px; }

#sbi_images {
  width: 95%;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between; }
  @media all and (min-width: 930px) {
    #sbi_images {
      width: 830px; } }

.sbi_item {
  width: 32%;
  height: 32%;
  margin-bottom: 10px;
  overflow: hidden; }
  @media all and (min-width: 520px) {
    .sbi_item {
      width: 24%;
      height: 24%; } }

.sbi_photo {
  height: 200px;
  display: flex; }
  .sbi_photo > img {
    display: block; }

.news__h3--date {
  font-size: 1.8rem;
  border-top: 1px dashed gray;
  padding-top: 20px;
  margin-bottom: 5px; }

.news__h3--ttl {
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.6;
  font-weight: normal;
  border-bottom: 1px dashed gray;
  padding-bottom: 15px; }
  @media all and (min-width: 800px) {
    .news__h3--ttl {
      font-size: 2.8rem;
      line-height: inherit; } }

.tl {
  overflow: hidden;
  background-image: url(../../assets/images/recruit/dot_v.png), url(../../assets/images/recruit/dot_h.png);
  background-repeat: repeat-y, repeat-x;
  background-position: center top, center 40px; }
  @media all and (min-width: 800px) {
    .tl {
      background-position: center top, center 67px; } }
  .tl__r dt {
    float: right; }
  .tl__r dd {
    float: left; }
  .tl__l dt {
    float: left; }
    .tl__l dt .tl__dt--time {
      text-align: right; }
    .tl__l dt .tl__dt--step {
      display: inline-block; }
  .tl__l dd {
    float: right; }
  .tl__dt {
    width: 42.55%;
    padding: 5% 0 5% 0;
    line-height: 1.7; }
    .tl__dt--time {
      color: #f79327;
      font-size: 4rem;
      margin-bottom: 15px;
      display: block;
      line-height: 1;
      background: #e3e3e3; }
      @media all and (min-width: 800px) {
        .tl__dt--time {
          font-size: 5rem; } }
    .tl__dt--step {
      color: #f79327;
      font-size: 2.2rem;
      padding-bottom: 5px;
      display: block;
      line-height: 1;
      letter-spacing: -0.1rem;
      background: #e3e3e3; }
      @media all and (min-width: 800px) {
        .tl__dt--step {
          font-size: 3.2rem; } }
      .tl__dt--step svg {
        width: 22px;
        height: 19px;
        fill: rgba(0, 0, 0, 0.25);
        padding: 0 5px; }
        @media all and (min-width: 800px) {
          .tl__dt--step svg {
            width: 28px;
            height: 26px;
            padding-left: 15px;
            padding: 0 10px; } }
    .tl__dt--ttl {
      font-size: 2.4rem;
      display: block;
      font-weight: bold;
      padding-bottom: 15px;
      line-height: 1.2;
      background: #e3e3e3; }
      @media all and (min-width: 800px) {
        .tl__dt--ttl {
          font-size: 3.6rem; } }
      .tl__dt--ttl b {
        letter-spacing: -0.8rem; }
    .tl__dt--txt {
      display: block;
      margin-bottom: 15px; }
  .tl__dd {
    width: 42.55%; }
    .tl__dd figure {
      width: 100%; }
  .tl__area {
    width: 100%;
    overflow: hidden; }
    @media all and (min-width: 1000px) {
      .tl__area {
        margin: 0 auto;
        width: 940px; } }

.recruit_01 {
  padding: 30px 0;
  background: url(../../assets/images/recruit/bg_eiffel.jpg) no-repeat;
  background-position: 60% bottom;
  background-size: cover; }
  @media all and (min-width: 560px) {
    .recruit_01 {
      background-position: left bottom;
      background-size: 120%; } }
  @media all and (min-width: 650px) {
    .recruit_01 {
      padding: 90px 0; } }
  @media all and (min-width: 800px) {
    .recruit_01 {
      background-position: left bottom; } }
  @media all and (min-width: 1000px) {
    .recruit_01 {
      background-position: left bottom;
      background-size: 100%; } }
  @media all and (min-width: 1100px) {
    .recruit_01 {
      background-position: center bottom;
      background-size: cover; } }
  .recruit_01__txt {
    width: 100%;
    color: white;
    text-shadow: 1px 1px 1px black; }
    @media all and (min-width: 560px) {
      .recruit_01__txt {
        width: 500px; } }
    .recruit_01__txt_01 {
      font-size: 1.4rem; }
      @media all and (min-width: 560px) {
        .recruit_01__txt_01 {
          text-align: center; } }

.recruit_02 {
  background: url(../../assets/images/recruit/bg_salon.jpg) center top no-repeat;
  background-size: cover; }
  .recruit_02__body {
    padding: 5%; }
    @media all and (min-width: 780px) {
      .recruit_02__body {
        padding: 2%; } }
    .recruit_02__body:first-child {
      border-bottom: 15px solid #e3e3e3; }
  .recruit_02__dl {
    background: rgba(255, 255, 255, 0.7);
    padding: 5%;
    overflow: hidden; }
    @media all and (min-width: 780px) {
      .recruit_02__dl {
        padding: 0; } }
    @media all and (min-width: 780px) {
      .recruit_02__dl_gr01 dt {
        float: right; } }
    @media all and (min-width: 780px) {
      .recruit_02__dl_gr01 dd {
        float: left; } }
    @media all and (min-width: 780px) {
      .recruit_02__dl_gr02 dt {
        float: left; } }
    @media all and (min-width: 780px) {
      .recruit_02__dl_gr02 dd {
        float: right; } }
    .recruit_02__dl dt {
      width: 100%; }
      @media all and (min-width: 780px) {
        .recruit_02__dl dt {
          width: 50%; } }
      @media all and (min-width: 780px) {
        .recruit_02__dl dt .ttl__h3--txt {
          margin-top: 45px; } }
    .recruit_02__dl dd {
      width: 100%;
      margin-top: 30px; }
      @media all and (min-width: 780px) {
        .recruit_02__dl dd {
          width: 50%;
          margin-top: 0; } }

.box_01 {
  background: rgba(255, 255, 255, 0.7);
  border: 3px solid rgba(0, 0, 0, 0.3);
  padding: 5%; }
  @media all and (min-width: 650px) {
    .box_01 {
      padding: 4%; } }
  @media all and (min-width: 800px) {
    .box_01 {
      padding: 2%; } }
  .box_01__ttl {
    color: #f79327;
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 5px; }
    .box_01__ttl svg {
      width: 48px;
      height: 35px;
      fill: rgba(0, 0, 0, 0.5);
      vertical-align: -7px;
      padding-right: 7px; }
  .box_01__txt {
    font-size: 1.6rem;
    line-height: 1.5; }
    @media all and (min-width: 800px) {
      .box_01__txt {
        font-size: 1.8rem; } }

.sbi-screenreader {
  display: none; }

/**
 * Owl Carousel v2.3.2
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.2
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.2
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.vegas-wrapper,
.vegas-overlay,
.vegas-timer,
.vegas-slide,
.vegas-slide-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0; }

.vegas-overlay {
  opacity: .5;
  background: transparent url("overlays/02.png") center center repeat; }

.vegas-timer {
  top: auto;
  bottom: 0;
  height: 2px; }

.vegas-timer-progress {
  width: 0%;
  height: 100%;
  background: white;
  -webkit-transition: width ease-out;
  transition: width ease-out; }

.vegas-timer-running .vegas-timer-progress {
  width: 100%; }

.vegas-slide,
.vegas-slide-inner {
  margin: 0;
  padding: 0;
  background: transparent center center no-repeat;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform, opacity; }

body .vegas-container {
  overflow: hidden !important;
  position: relative; }

.vegas-video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; }

body.vegas-container {
  overflow: auto;
  position: static;
  z-index: -2; }

body.vegas-container > .vegas-timer,
body.vegas-container > .vegas-overlay,
body.vegas-container > .vegas-slide {
  position: fixed;
  z-index: -1; }

/* Target Safari IOS7+ in order to add 76px */
_::full-page-media, _:future,
:root body.vegas-container > .vegas-slide,
:root body.vegas-container > .vegas-overlay {
  bottom: -76px; }

/*******************************************/
/* blur transition */
/*******************************************/
.vegas-transition-blur,
.vegas-transition-blur2 {
  opacity: 0;
  -webkit-filter: blur(32px);
  filter: blur(32px); }

.vegas-transition-blur-in,
.vegas-transition-blur2-in {
  opacity: 1;
  -webkit-filter: blur(0px);
  filter: blur(0px); }

.vegas-transition-blur2-out {
  opacity: 0; }

/*******************************************/
/* burn transition */
/*******************************************/
.vegas-transition-burn,
.vegas-transition-burn2 {
  opacity: 0;
  -webkit-filter: contrast(1000%) saturate(1000%);
  filter: contrast(1000%) saturate(1000%); }

.vegas-transition-burn-in,
.vegas-transition-burn2-in {
  opacity: 1;
  -webkit-filter: contrast(100%) saturate(100%);
  filter: contrast(100%) saturate(100%); }

.vegas-transition-burn2-out {
  opacity: 0;
  -webkit-filter: contrast(1000%) saturate(1000%);
  filter: contrast(1000%) saturate(1000%); }

/*******************************************/
/* fade transition */
/*******************************************/
.vegas-transition-fade,
.vegas-transition-fade2 {
  opacity: 0; }

.vegas-transition-fade-in,
.vegas-transition-fade2-in {
  opacity: 1; }

.vegas-transition-fade2-out {
  opacity: 0; }

/*******************************************/
/* flash transition */
/*******************************************/
.vegas-transition-flash,
.vegas-transition-flash2 {
  opacity: 0;
  -webkit-filter: brightness(25);
  filter: brightness(25); }

.vegas-transition-flash-in,
.vegas-transition-flash2-in {
  opacity: 1;
  -webkit-filter: brightness(1);
  filter: brightness(1); }

.vegas-transition-flash2-out {
  opacity: 0;
  -webkit-filter: brightness(25);
  filter: brightness(25); }

/*******************************************/
/* negative transition */
/*******************************************/
.vegas-transition-negative,
.vegas-transition-negative2 {
  opacity: 0;
  -webkit-filter: invert(100%);
  filter: invert(100%); }

.vegas-transition-negative-in,
.vegas-transition-negative2-in {
  opacity: 1;
  -webkit-filter: invert(0);
  filter: invert(0); }

.vegas-transition-negative2-out {
  opacity: 0;
  -webkit-filter: invert(100%);
  filter: invert(100%); }

/*******************************************/
/* slideDown transition */
/*******************************************/
.vegas-transition-slideDown,
.vegas-transition-slideDown2 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

.vegas-transition-slideDown-in,
.vegas-transition-slideDown2-in {
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

.vegas-transition-slideDown2-out {
  -webkit-transform: translateY(100%);
  transform: translateY(100%); }

/*******************************************/
/* slideLeft transition */
/*******************************************/
.vegas-transition-slideLeft,
.vegas-transition-slideLeft2 {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.vegas-transition-slideLeft-in,
.vegas-transition-slideLeft2-in {
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

.vegas-transition-slideLeft2-out {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

/*******************************************/
/* slideRight transition */
/*******************************************/
.vegas-transition-slideRight,
.vegas-transition-slideRight2 {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.vegas-transition-slideRight-in,
.vegas-transition-slideRight2-in {
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }

.vegas-transition-slideRight2-out {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

/*******************************************/
/* slideUp transition */
/*******************************************/
.vegas-transition-slideUp,
.vegas-transition-slideUp2 {
  -webkit-transform: translateY(100%);
  transform: translateY(100%); }

.vegas-transition-slideUp-in,
.vegas-transition-slideUp2-in {
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

.vegas-transition-slideUp2-out {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%); }

/*******************************************/
/* swirlLeft transition */
/*******************************************/
.vegas-transition-swirlLeft,
.vegas-transition-swirlLeft2 {
  -webkit-transform: scale(2) rotate(35deg);
  transform: scale(2) rotate(35deg);
  opacity: 0; }

.vegas-transition-swirlLeft-in,
.vegas-transition-swirlLeft2-in {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1; }

.vegas-transition-swirlLeft2-out {
  -webkit-transform: scale(2) rotate(-35deg);
  transform: scale(2) rotate(-35deg);
  opacity: 0; }

/*******************************************/
/* swirlRight transition */
/*******************************************/
.vegas-transition-swirlRight,
.vegas-transition-swirlRight2 {
  -webkit-transform: scale(2) rotate(-35deg);
  transform: scale(2) rotate(-35deg);
  opacity: 0; }

.vegas-transition-swirlRight-in,
.vegas-transition-swirlRight2-in {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1; }

.vegas-transition-swirlRight2-out {
  -webkit-transform: scale(2) rotate(35deg);
  transform: scale(2) rotate(35deg);
  opacity: 0; }

/*******************************************/
/* zoomIn transition */
/*******************************************/
.vegas-transition-zoomIn,
.vegas-transition-zoomIn2 {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0; }

.vegas-transition-zoomIn-in,
.vegas-transition-zoomIn2-in {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.vegas-transition-zoomIn2-out {
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0; }

/*******************************************/
/* zoomOut transition */
/*******************************************/
.vegas-transition-zoomOut,
.vegas-transition-zoomOut2 {
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0; }

.vegas-transition-zoomOut-in,
.vegas-transition-zoomOut2-in {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.vegas-transition-zoomOut2-out {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0; }

/*******************************************/
/* kenburns animation */
/*******************************************/
.vegas-animation-kenburns {
  -webkit-animation: kenburns ease-out;
  animation: kenburns ease-out; }

@-webkit-keyframes kenburns {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes kenburns {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/*******************************************/
/* kenburnsDownLeft animation */
/*******************************************/
.vegas-animation-kenburnsDownLeft {
  -webkit-animation: kenburnsDownLeft ease-out;
  animation: kenburnsDownLeft ease-out; }

@-webkit-keyframes kenburnsDownLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, -10%);
    transform: scale(1.5) translate(10%, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsDownLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, -10%);
    transform: scale(1.5) translate(10%, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsDownRight animation */
/*******************************************/
.vegas-animation-kenburnsDownRight {
  -webkit-animation: kenburnsDownRight ease-out;
  animation: kenburnsDownRight ease-out; }

@-webkit-keyframes kenburnsDownRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, -10%);
    transform: scale(1.5) translate(-10%, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsDownRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, -10%);
    transform: scale(1.5) translate(-10%, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsDown animation */
/*******************************************/
.vegas-animation-kenburnsDown {
  -webkit-animation: kenburnsDown ease-out;
  animation: kenburnsDown ease-out; }

@-webkit-keyframes kenburnsDown {
  0% {
    -webkit-transform: scale(1.5) translate(0, -10%);
    transform: scale(1.5) translate(0, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsDown {
  0% {
    -webkit-transform: scale(1.5) translate(0, -10%);
    transform: scale(1.5) translate(0, -10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsLeft animation */
/*******************************************/
.vegas-animation-kenburnsLeft {
  -webkit-animation: kenburnsLeft ease-out;
  animation: kenburnsLeft ease-out; }

@-webkit-keyframes kenburnsLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 0);
    transform: scale(1.5) translate(10%, 0); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 0);
    transform: scale(1.5) translate(10%, 0); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsRight animation */
/*******************************************/
.vegas-animation-kenburnsRight {
  -webkit-animation: kenburnsRight ease-out;
  animation: kenburnsRight ease-out; }

@-webkit-keyframes kenburnsRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 0);
    transform: scale(1.5) translate(-10%, 0); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 0);
    transform: scale(1.5) translate(-10%, 0); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsUpLeft animation */
/*******************************************/
.vegas-animation-kenburnsUpLeft {
  -webkit-animation: kenburnsUpLeft ease-out;
  animation: kenburnsUpLeft ease-out; }

@-webkit-keyframes kenburnsUpLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 10%);
    transform: scale(1.5) translate(10%, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsUpLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 10%);
    transform: scale(1.5) translate(10%, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsUpRight animation */
/*******************************************/
.vegas-animation-kenburnsUpRight {
  -webkit-animation: kenburnsUpRight ease-out;
  animation: kenburnsUpRight ease-out; }

@-webkit-keyframes kenburnsUpRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 10%);
    transform: scale(1.5) translate(-10%, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsUpRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 10%);
    transform: scale(1.5) translate(-10%, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

/*******************************************/
/* kenburnsUp animation */
/*******************************************/
.vegas-animation-kenburnsUp {
  -webkit-animation: kenburnsUp ease-out;
  animation: kenburnsUp ease-out; }

@-webkit-keyframes kenburnsUp {
  0% {
    -webkit-transform: scale(1.5) translate(0, 10%);
    transform: scale(1.5) translate(0, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }

@keyframes kenburnsUp {
  0% {
    -webkit-transform: scale(1.5) translate(0, 10%);
    transform: scale(1.5) translate(0, 10%); }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0); } }
