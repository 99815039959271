body { color:txt01(1);background:base01(1);width:100%;font-size:1.5rem;line-height: 2; }

a { color: txt01(0.8); text-decoration: none; }
::selection,::-moz-selection { background: effect02(1); }

.hover {
  filter:alpha(opacity=100);
  opacity:1;
  @extend %transition-all-linear;
  &:hover, &:focus {
    filter:alpha(opacity=70);
    opacity:0.7;
    @extend %transition-all-linear;
  }
}

$i: 5;
@for $value from 0 through 6 {
  .m_#{ $value * $i } {
    margin: $i * $value + px !important;
  }
  .mt_#{ $value * $i } {
    margin-top: $i * $value  + px !important;
  }
  .mb_#{ $value * $i } {
    margin-bottom: $i * $value + px !important;
  }
  .ml_#{ $value * $i } {
    margin-left: $i * $value + px !important;
  }
  .mr_#{ $value * $i } {
    margin-right: $i * $value + px !important;
  }
  .p_#{ $value * $i } {
    padding: $i * $value + px !important;
  }
  .pt_#{ $value * $i } {
    padding-top: $i * $value + px !important;
  }
  .pb_#{ $value * $i } {
    padding-bottom: $i * $value + px !important;
  }
  .pl_#{ $value * $i } {
    padding-left: $i * $value + px !important;
  }
  .pr_#{ $value * $i } {
    padding-right: $i * $value + px !important;
  }
}

$j: 15;
@for $value from 1 through 9 {
  .spacer {
    &_#{ $value * $j } {
      margin-bottom: $value * $j + px !important;
      @media all and (min-width:800px) {
        margin-bottom: ($value + 1) * $j + px !important;
      }
    }
  }
}

.spacer_60-30 {
  margin-bottom: 30px !important;
  @media all and (min-width:800px) {
    margin-bottom: 60px !important;
  }
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

// common

.container {
  width: 100%;
  padding: 30px 0;
  background: base01(1);
  @media all and (min-width:800px) {
    padding: 60px 0;
  }
  &--bg_frame {
    background: wh(1);
    border: 15px solid base01(1);
    box-sizing: border-box;
  }
  &--padding0 {
    padding: 0;
  }
  &__body {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    @media all and (min-width:1120px) {
      width: 1000px;
    }
  }
}

.external_link_icon {
  fill: effect01(1);
  width: 14px;
  height: 14px;
  padding: 0 5px;
}

.ttl {
  &__h2 {
    font-size: 1.4rem;
    text-align: center;
    line-height: 1;
    position: relative;
    font-weight: bold;
    margin: 0 0 60px 0;
    &--txt {
      @extend %didot;
      font-size: 4.5rem;
      font-style: italic;
      line-height: 0.75;
      & span {
        font-size: 1.4rem;
        line-height: 1;
        @extend %yu-go;
        font-style: normal;
      }
    }
    &__svg {
      display: block;
      margin: 0 auto 10px;
      &_news {
        width: 98px;
        height: 33px;
      }
      &_salon {
        width: 102px;
        height: 34px;
      }
      &_recruit {
        width: 225px;
        height: 55px;
        &_02 {
          width: 122px;
          height: 34px;
        }
      }
      &_ig {
        width: 199px;
        height: 47px;
      }
      &_about {
        width: 158px;
        height: 34px;
      }
    }
    &:after {
      content: '';
      width: 180px;
      display: inline-block;
      border-bottom: 1px solid bk(1);
      position: absolute;
      left: 0;
      right: 0;
      top: 65%;
      margin: auto;
    }
  }

  &__h3 {
    text-align: center;
    border-top: 1px solid bk(1);
    border-bottom: 1px solid bk(1);
    clear: both;
    margin-bottom: 30px;
    &__svg {
      margin-top: 10px;
      &_jd {
        width: 133px;
        height: 36px;
      }
      &_cl {
        width: 190px;
        height: 36px;
      }
    }
    &__nm {
      font-size: 1.5rem;
      font-weight: bold;
      vertical-align: 8px;
      padding-left: 5px;
    }
    &--txt {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.5;
      @extend .spacer_30;
      & span {
        @extend %didot;
        font-size: 2.8rem;
      }
    }
  }

  &__h4 {
    &--en {
      text-align: center;
      @extend %didot;
      font-size: 2.4rem;
      @extend .spacer_30;
    }
    &--ja {
      @extend %hannari;
      font-size: 4rem;
      text-align: left;
      margin-bottom: 30px;
      line-height: 1.4;
      @media all and (min-width:650px) {
        font-size: 4.5rem;
      }
      &__icon {
        &_hito {
          width: 22px;
          height: 47px;
          vertical-align: -5px;
          @media all and (min-width:650px) {
            width: 30px;
            height: 60px;
            vertical-align: -7px;
          }
        }
        &_flag {
          width: 35px;
          height: 37px;
          vertical-align: -4px;
          @media all and (min-width:650px) {
            width: 43px;
            height: 45px;
            vertical-align: -3px;
          }
        }
        &_house {
          width: 47px;
          height: 47px;
          vertical-align: -3px;
          @media all and (min-width:650px) {
            width: 60px;
            height: 60px;
            vertical-align: -7px;
          }
        }
        &_eiffel {
          width: 33px;
          height: 50px;
          vertical-align: -7px;
          @media all and (min-width:650px) {
            width: 40px;
            height: 60px;
            vertical-align: -7px;
          }
        }
        &_clock {
          width: 40px;
          height: 40px;
          vertical-align: -6px;
          @media all and (min-width:650px) {
            width: 45px;
            height: 45px;
            vertical-align: -6px;
          }
        }
        &_study {
          width: 33px;
          height: 19px;
          vertical-align: -3px;
          @media all and (min-width:650px) {
            width: 33px;
            height: 19px;
            vertical-align: -3px;
          }
        }
        &_cut {
          width: 33px;
          height: 35px;
          vertical-align: -8px;
          @media all and (min-width:650px) {
            width: 33px;
            height: 35px;
            vertical-align: -8px;
          }
        }
      }
    }
  }
}

.sub .ttl__h2 {
  margin-top: 90px;
  @media all and (min-width:800px) {
    margin-top: 120px;
  }
}

.header {
  width: 100%;
  height: 60px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  &__logo {
    height: 60px;
    text-align: center;
    line-height: 1;
    & svg {
      margin-top: 9px;
      width: 136px;
      height: 40px;
    }
  }
  & .container__body  {
    width: 90%;
    margin: 0 5%;
    border-bottom: 1px solid bk(1);
    box-sizing: border-box;
    position: relative;
    z-index: 1100;
  }
}

.index .header .container__body {
  display: none;
}
.index .header.open .container__body {
  display: block !important;
}

.visible {
  & .container__body {
    display: block !important;
  }
}
.open {
  & .gnav__menuBtn__icon {
    fill: wh(1);
    @extend %transition-all-linear;
  }
  & .container__body {
    display: block;
    border-bottom: 1px solid wh(1);
    @extend %transition-all-linear;
    & .header__logo__a svg {
      fill: wh(1);
      @extend %transition-all-linear;
    }
  }
}

// footer
.footer {
  padding: 0;
  & .container__body {
    padding: 15px 0;
    width: 90%;
    border-top: 1px solid bk(1);
  }
  &__cp {
    text-align: center;
    font-size: 1.1rem;
  }
  &__linkjd {
    text-align: center;
    margin-bottom: 10px;
    & a {
      background: wh(0.5);
      color: bk(1);
      border: 1px solid bk(1);
      padding: 9px 10px 6px 12px;
      line-height: 1;
      @extend %josef;
      font-weight: bold;
      box-sizing: border-box;
      &:hover, &:focus {
        background: wh(1);
        @extend %transition-all-linear;
      }
    }
    & .arrow {
      width: 8px;
      height: 10px;
      vertical-align: -1px;
    }
  }
}

#map_area {
  height: 250px;
}

// gnav
$menu_size:20px;
.gnav {
  width: 100%;
  font-size: 1.4rem;
  background: bk(0.7);
  position: fixed;
  top: 0;
  z-index: 1000;
  &__body {
    padding-top: 65px;
    display: none;
    margin: 0 auto;
    overflow: hidden;
    &__item {
      display: block;
      &:nth-child(2n) {
        background: bk(0.2);
      }
      & a {
        width: 90%;
        display: block;
        padding: 3px 5%;
        color: wh(1);
        text-align: center;
        &:hover, &:focus {
          filter:alpha(opacity=70);
          opacity:0.7;
          @extend %transition-all-linear;
        }
        &.current {
          color: effect01(1);
        }
      }
      &.child {
        text-indent: 1rem;
        background: bk(0.5);
        font-size: 85%;
        &:nth-child(2n) {
          
        }
        & a {
          color: wh(0.7);
        }
      }

    }
  }
}

.gnav__menuBtn {
  width: $menu_size;
  height: $menu_size;
  position: fixed;
  top: 15px;
  right: 5%;
  z-index: 2000;
  cursor: pointer;
  &__icon {
    display: block;
    width: $menu_size;
    height: $menu_size;
    fill: txt01(1);
  }
}

.postarea {
  h4 {
    font-size: 2rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  & img {
    width: 100%;
    height: inherit;
    
    &.vertically-L {
    }
    &.vertically-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @media all and (min-width:800px) {
        width: inherit;
      }
    }
    &.horizontally-L {
    }
    &.horizontally-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @media all and (min-width:800px) {
        width: inherit;
      }
    }
  }
}

.pager {
  clear: both;
  text-align: center;
  margin-bottom: 30px;
  @media all and (min-width:800px) {
    margin-bottom: 60px;
  }
  & span {
    margin: 0 2px;
    &.current {
      background: sub01(1);
      color: wh(1);
      padding: 5px 9px;
      margin: 0 2px;
      &:hover , &:focus {
        background: sub01(1);
      }
    }
  }
  & a {
    padding: 5px 9px;
    margin: 0 5px;
    color: txt01(1);
    text-decoration: none;
    &:hover , &:focus {
      color: effect01(1);
    }
    &.page {
      background: base02(0.8);
      &:hover , &:focus {
        background: effect02(1);
        color: wh(1);
      }
    }
  }
  & .page-numbers {
    background: base02(0.8);
    &:hover , &:focus {
      background: effect02(1);
      color: wh(1);
    }
    &.next, &.prev {
      background: transparent;
      &:hover, &:focus {
        background: transparent;
        color: effect02(1);
      }
    }
  }
}

.formtbl {
  width: 100%;
  margin: 0 auto;
  @media all and (min-width:800px) {
    width: 700px;
  }
  & th {
    width: 100%;
    text-align: left;
    background: sub03(1);
    color: wh(1);
    padding: 15px;
    font-weight: normal;
    box-sizing: border-box;
    vertical-align: top;
    display: block;
    @media all and (min-width:650px) {
      width: 25%;
      display: table-cell;
      border-right: 5px solid wh(1);
      border-bottom: 5px solid wh(1);
    }
  }
  & td {
    border-bottom: 5px solid wh(1);
    background: sub01(0.24);
    padding: 15px;
    box-sizing: border-box;
    display: block;
    @media all and (min-width:650px) {
      display: table-cell;
    }
  }
  & input, textarea {
    border: none;
    width: 94%;
    padding: 10px 3%;
    &.check {
      width: inherit;
    }
  }
  & #rule {
    text-indent: -2.5rem;
    padding-left: 2.5rem;
    & a {
      color: effect01(1);
      text-decoration: underline;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  & .error {
    color: effect01(1);
    font-size: 80%;
  }
}

.btn__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100% !important;
  height: 70px !important;
  border: none;
  background: transparent;
  cursor: pointer;
}

.form__btns {
  margin: 45px auto 0;
  overflow: hidden;
  @media all and (min-width:650px) {
    width: 530px;
  }
  & .btn__small {
    margin: 0;
    clear: none;
  }
  & .btn__back {
    @media all and (min-width:650px) {
      float: left;
    }
  }
  & .btn__send {
    @media all and (min-width:650px) {
      float: right;
    }
  }
}

.br_sp {
  display: inline;
  @media all and (min-width:650px) {
    display: none;
  }
}